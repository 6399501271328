import Vue from 'vue'
import FeatherIcon from '@core/components/feather-icon/FeatherIcon.vue'
import dayjs from 'dayjs'
import localePl from 'dayjs/locale/pl'
import isToday from 'dayjs/plugin/isToday'
import localeData from 'dayjs/plugin/localeData'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import weekday from 'dayjs/plugin/weekday'

dayjs.locale(localePl)
dayjs.extend(isToday)
dayjs.extend(localeData)
dayjs.extend(weekOfYear)
dayjs.extend(weekday)

Vue.component(FeatherIcon.name, FeatherIcon)
