export default [
  {
    path: '/schedule/my-availability',
    name: 'schedule-my-availability',
    meta: {
      breadcrumb: [
        { text: 'schedule.title' },
        { text: 'schedule.my-availability.title', active: true },
      ],
      pageTitle: 'schedule.my-availability.title',
      roles: ['ROLE_USER'],
    },
    component: () => import('@/views/schedule/availability/MyAvailability'),
  },
  {
    path: '/schedule/planner',
    name: 'schedule-planner',
    meta: {
      breadcrumb: [
        { text: 'schedule.title' },
        { text: 'schedule.planner.title', active: true },
      ],
      pageTitle: 'schedule.planner.title',
      roles: ['ROLE_HR_MANAGER'],
    },
    component: () => import('@/views/schedule/planner/Planner'),
  },
]
