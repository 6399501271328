import state from './moduleScheduleState'
import mutations from './moduleScheduleMutations'
import actions from './moduleScheduleActions'
import getters from './moduleScheduleGetters'

export default {
  namespaced: true,
  state,
  mutations,
  getters,
  actions,
}
