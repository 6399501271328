import scheduleRequests from '../../http/requests/schedule/schedule'

export default {
  fetchAvailabilities: ({ commit }, month) => new Promise((resolve, reject) => {
    scheduleRequests.getMyAvailabilities(month)
      .then(response => {
        commit('SET_AVAILABILITIES', response.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  addAvailabilities: (_, payload) => new Promise((resolve, reject) => {
    scheduleRequests.addAvailabilities(payload)
      .then(response => {
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  deleteAvailabilities: (_, ids) => new Promise(() => {
    scheduleRequests.deleteAvailabilities(ids)
  }),
}
