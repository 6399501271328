/* eslint-disable no-prototype-builtins,no-restricted-syntax */
import moment from 'moment'
import _ from 'lodash'

const cloneNested = obj => _.cloneDeep(obj)
const randomPassword = () => Array(20)
  .fill('0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz')
  // eslint-disable-next-line no-mixed-operators
  .map(x => x[Math.floor(crypto.getRandomValues(new Uint32Array(1))[0] / (0xffffffff + 1) * x.length)])
  .join('')

const checkNested = (obj, level, ...rest) => {
  if (obj === undefined) return false
  if (rest.length === 0 && obj.hasOwnProperty(level)) return true
  return checkNested(obj[level], ...rest)
}

const strToDecimal = str => Number(Number(str).toFixed(2))

const parseRequestError = err => {
  if (checkNested(err, 'message')) {
    window.toast.notify.danger(err.message)
  }

  if (checkNested(err, 'errors', 'children')) {
    // eslint-disable-next-line guard-for-in
    for (const key in err.errors.children) {
      const elem = err.errors.children[key]
      if (checkNested(elem, 'errors')) {
        elem.errors.forEach(e => {
          window.toast.notify.warning(key.charAt(0)
            .toUpperCase() + key.slice(1), e)
        })
      }
    }
  }
}

const capitalizeFirstLetter = val => String(val).charAt(0).toUpperCase() + String(val).slice(1)
const empty = data => {
  if (typeof (data) === 'number' || typeof (data) === 'boolean') {
    return false
  }
  if (typeof (data) === 'undefined' || data === null) {
    return true
  }
  if (typeof (data.length) !== 'undefined') {
    return data.length === 0
  }
  let count = 0
  for (const i in data) {
    if (data.hasOwnProperty(i)) {
      count += 1
    }
  }
  return count === 0
}
const transformDatetimeFromHumanReadable = date => moment(date, 'YYYY-MM-DD HH:mm')
  .format('YYYY-MM-DDTHH:mm:ssZ')
const transformDatetimeToHumanReadable = date => moment(date, 'YYYY-MM-DDTHH:mm:ssZ')
  .format('YYYY-MM-DD HH:mm')
const transformDatetimeToHumanReadablePretty = date => moment(date, 'YYYY-MM-DDTHH:mm:ssZ')
  .format('DD.MM.YYYY HH:mm')

export {
  cloneNested,
  randomPassword,
  parseRequestError,
  checkNested,
  empty,
  transformDatetimeFromHumanReadable,
  transformDatetimeToHumanReadable,
  transformDatetimeToHumanReadablePretty,
  strToDecimal,
  capitalizeFirstLetter,
}
