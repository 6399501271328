import address from '@/router/address/address'
import customer from '@/router/customer/customer'
import order from '@/router/order/order'
import user from '@/router/user/user'
import vehicle from '@/router/vehicle/vehicle'
import team from '@/router/team/team'
import report from '@/router/report/report'
import settings from '@/router/settings/settings'
import schedule from '@/router/schedule/schedule'

const dashboard = [
  {
    path: '/',
    name: 'dashboard',
    meta: {
      pageTitle: 'dashboard.title',
      roles: ['ROLE_USER'],
    },
    component: () => import('@/views/dashboard/Dashboard'),
  },
  {
    path: '/dashboard/finance',
    name: 'dashboard-finance',
    meta: {
      breadcrumb: [
        { text: 'dashboard.finance.title', active: true },
      ],
      pageTitle: 'dashboard.finance.title',
      roles: ['ROLE_FINANCE_MANAGER'],
    },
    component: () => import('@/views/dashboard/FinanceDashboard'),
  },
  {
    path: '/dashboard/map',
    name: 'dashboard-map',
    meta: {
      breadcrumb: [
        { text: 'dashboard.map.title', active: true },
      ],
      pageTitle: 'dashboard.map.title',
      roles: ['ROLE_DISPATCHER'],
    },
    component: () => import('@/views/dashboard/MapDashboard'),
  },
]

export default [
  ...address,
  ...customer,
  ...dashboard,
  ...order,
  ...settings,
  ...schedule,
  ...user,
  ...vehicle,
  ...team,
  ...report,
]
