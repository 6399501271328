import Vue from 'vue'
import Vuex from 'vuex'

import moduleUser from '@/store/user/moduleUser'
import moduleVehicle from '@/store/vehicle/moduleVehicle'
import moduleTeam from '@/store/team/moduleTeam'
import moduleOrder from '@/store/order/moduleOrder'
import moduleAddress from '@/store/address/moduleAddress'
import moduleCustomer from '@/store/customer/moduleCustomer'
import moduleFilter from '@/store/filters/moduleFilter'
import moduleReport from '@/store/report/moduleReport'
import moduleAttachment from '@/store/attachment'
import moduleSchedule from '@/store/schedule/moduleSchedule'
import verticalMenu from './vertical-menu'
import appConfig from './app-config'
import app from './app'
import moduleAuth from './auth/moduleAuth'
import moduleSettings from './settings/moduleSettings'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    app,
    appConfig,
    verticalMenu,
    auth: moduleAuth,
    settings: moduleSettings,
    user: moduleUser,
    vehicle: moduleVehicle,
    team: moduleTeam,
    order: moduleOrder,
    address: moduleAddress,
    customer: moduleCustomer,
    filter: moduleFilter,
    report: moduleReport,
    attachment: moduleAttachment,
    schedules: moduleSchedule,
  },
  strict: process.env.DEV,
})
